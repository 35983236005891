.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10%;
    text-align: center;
}

.login-container button.primary-button, .login-container a.primary-button   {
    background-color: #198754;
    border-color: #198754;
}

.login-container button.primary-button :hover, .login-container a.primary-button :hover {
    background-color: #105032;
    border-color: #105032;
}

.login-container button.primary-button:active, .login-container a.primary-button:active {
    background-color: #209961;
    border-color: #209961;
}


.idp-list {
    list-style: none;
    display: flex;
    justify-content: center;
}

.section-title {
    margin-top: 5%;
}

.idp-list li {
    margin: 0.5rem;
}