body {
  font-family: system-ui, sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
}

button {
  font-family: system-ui, sans-serif;
}

#wrapper {
  padding: 2rem;
}

.menu-container {
  display: flex;
  flex-direction: column;
}

.menu-container ul {
  list-style: none;
  margin-block-start: 0;
  margin-inline-start: 0;
  padding-inline-start: 0;
}

#tabs {
  display: flex;
  width: 100%;
}

#tabs ul {
  display: flex;
  list-style: none;
  width: 80%;
  justify-content: space-between;
}

#tabs li {
  padding: 0.5rem 3rem;
  background-color: #EDF3EC;
  font-size: 1rem;
}

#tabs li a {
  font-size: 1.5rem;
  font-style: italic;
  width: 100%;
  height: 100%;
  color: black;
}

#tabs li:not(.selected-tab) a {
  text-decoration: none;
}

#tabs a:visited {
  color: black
}

#tabs li:hover {
  background-color: #86A176;
  cursor: pointer;
}

#tabs li.selected-tab a {
  text-decoration-style: dashed;
}

#avatar-bio-wrapper {
  display: flex;
  padding-bottom: 6rem;
  justify-self: center;
  align-self: center;
  width: 80%;
  justify-items: center;
  justify-content: space-around;
}

#name-avatar-wrapper {
  display: flex;
  flex-direction: column;
}

#bio-wrapper {
  padding: 0 2.7rem;
}

#bio {
  margin-right: 3rem;
}

input {
  width: 300px;
  background:
    linear-gradient(#000, #000) center bottom 5px /calc(100% - 10px) 2px no-repeat;
  background-color: #fcfcfc;
  border: none;
  padding: 10px;
  font-size: 16px;
  font-weight: 200;
  letter-spacing: 1px;
}

input:disabled {
  background: none;
}

input[type="file"] {
  display: none;
}

@media (min-width: 768px) {

}

.container {
  padding: 2rem;
}

.avatar-toggles-container {
  padding: 2rem;
}

.avatar {
  border-radius: 50%;
  object-fit: cover;
}

.avatar-menu {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.avatar-profile {
  width: 200px;
  height: 200px;
}

.counter-button {
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  padding: 0;
}

.counter-button:hover {
  box-shadow: rgba(181, 189, 184, 0.35) 0 -25px 18px -14px inset, rgba(171, 180, 174, 0.25) 0 1px 2px, rgba(174, 185, 179, 0.25) 0 2px 4px, rgba(182, 185, 184, 0.25) 0 4px 8px, rgba(172, 180, 175, 0.25) 0 8px 16px, rgba(195, 199, 197, 0.25) 0 16px 32px;
}


.default-avatar-profile {
  width: 200px;
  height: 200px;
}

.album-toggle {
  display: none;
}

.album-title {
  cursor: pointer;
  text-decoration: underline;
}

.album-toggle:not(:checked)+span {
  display: none;
}

.album-toggle:checked+span {
  display: flex;
}

@media (min-width: 768px) {
  .primary-button {
    padding: 0.35rem 2.3rem;
  }
}

.spinner-container {
  padding: 2rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #000;
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
