.user-menu-dropdown button {
    background: none;
    border-color: #198754;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 2rem;
    cursor: pointer;
    padding: 0;
    white-space: normal;
}

.user-menu-dropdown button:hover {
    background-color: #198754;
}